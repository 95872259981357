/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import './variables/_variables.scss';
@import './variables/_variables-components.scss';
@import '../../@core/scss/base/bootstrap-extended/_variables.scss';

//overwrite styles for dark layout
.dark-layout {
  .MuiInputBase-input {
    color: #b4b7bd !important;
  }

  .MuiTextField-root {
    border: 1px solid #404657 !important;
  }

  .MuiSvgIcon-root[data-testid="CalendarIcon"],
  .MuiSvgIcon-root[data-testid="ClockIcon"] {
    color: white !important;
  }

  .bg-light-success2 {
    background: rgb(40, 199, 111, 0.55) !important;
  }

  .flatpickr-monthSelect-month {
    color: #b4b7bd !important;
  }

  .flatpickr-monthSelect-month.selected {
    color: #fff !important;
  }

  .flatpickr-monthSelect-month:hover {
    color: #fff !important;
  }
}

.main-menu {
  .navbar-header {
    height: $navbar-header-height;
  }

  .main-menu-content {
    height: calc(100% - #{$navbar-header-height}) !important;
    position: relative;

    .ps__rail-y {
      z-index: 3;
    }
  }
}

.navbar-header {
  .navbar-brand {
    .brand-logo img {
      max-width: 225px !important;
    }
  }
}

.auth-wrapper {
  .brand-logo-img {
    width: 100%;
  }
}

.strong {
  font-weight: bold;
}

.no-border {
  border: none !important;
}

.select__menu {
  z-index: 999;
}

.pt-05 {
  padding-top: 0.13rem !important;
}

.mr-05 {
  margin-right: 0.13rem !important;
}

.mx-0-75 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.prewrap {
  white-space: pre-wrap;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-right {
  text-align: right;
}

.display-none {
  display: none;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: 100% !important;
}

.brand-logo-confirm img {
  width: 100%;
  max-width: 300px;
}

.brand-logo-error img {
  width: 100%;
  max-width: 200px;
}

.readonly-background-none:not(disabled) {
  background-color: transparent !important;
  opacity: 1 !important;
}

body:not(.dark-layout) {
  .flatpickr-input:disabled {
    background-color: #efefef !important;
  }
}

@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 3000px !important;
  }
}

/*
================================================================================
 RESERVATION MODULE START
*/

.reservation-checkout {
  ul {
    padding-left: 0px
  }

  li.price-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }
}

.reservation-timeline {
  .timeline-event {
    min-height: 0.13rem !important;
  }
}

.details-table {
  tr {
    td {
      min-width: 10rem !important
    }
  }
}

/*
 RESERVATION MODULE END
 ================================================================================
*/


.payments {
  /*margin-bottom: 10px;*/
  /*font-size: 0*/
}

.payments a.payment {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative
}

.payments a.payment img {
  width: 120px;
  height: 120px;
  border: 1px solid #d8d8d8
}

.payments a.payment span.title {
  font-size: 8px;
  padding: 0 2px;
  color: #000;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  bottom: 5px;
  background-color: #fff;
  opacity: .9
}

.payments a.payment.active, .payments a.payment:hover {
  outline: 2px solid #005abb !important
}

.payments a.payment.warning {
  outline: 2px solid #ff8c00;
  border: none !important
}

.payments a.payment.disabled, .payments a.payment.disabled:hover, .payments a.payment.other:hover {
  outline: none !important
}

.payments a.payment.other {
  padding: 0;
  border: 0;
  width: inherit !important;
  height: inherit !important;
  font-size: 12px
}

.payments .payment-between {
  display: inline-block;
  font-size: 18px;
  margin-right: 15px;
  font-weight: 700
}

.payments a.payment.payment-with-input {
  width: 120px !important;
  height: 120px !important;
  border: 1px solid #d8d8d8;
  position: relative;
  overflow: visible;
  transform-origin: left;
  text-align: center;
}

.payments a.payment.payment-with-input input {
  color: #333;
  font-size: 11px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 100px;
  padding: 3px;
  border: 1px solid #d8d8d8;
  text-align: center;
  letter-spacing: 3px;
}

.payments a.payment.payment-with-input img {
  border: 0;
  width: 90px !important;
  height: 90px !important;
  position: absolute;
  left: calc(50% - 45px);
  top: 10px;
}

.payments a.payment.payment-with-input span {
  position: absolute;
  left: calc(50% - 45px);
  top: 10px;
}


.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  padding: 0.1rem 0.4rem !important;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding: 0.5rem 0.4rem !important;
}

.bg-light-success2 {
  background: #28c76f !important;
}

.MuiInputBase-input {
  padding: 0 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.45 !important;
  color: #6e6b7b !important;
  font-family: "Montserrat", Helvetica, Arial, serif !important;
}

.MuiInputBase-root {
  padding-right: 0 !important;
}

.autocomplete-container .suggestions-list {
  max-height: 115px !important;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: 94% !important;
  }
}

.label-elipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .content-header .breadcrumb {
    display: none;
  }
  .breadcrumbs-top {
    .content-header-title {
      display: contents !important;
    }
  }
}

.fallback-logo {
  max-width: 95vw !important;
}

@media (min-width: 768px) {
  .fallback-logo {
    max-width: 600px !important;
  }
}

.loading {
  .effect-1,
  .effect-2,
  .effect-3 {
    border-left: 3px solid $primary !important;
  }
}

.leaflet-container {
  z-index: 2 !important;
}
